@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.howToTrade-main-container{
    padding: 40px;
    display: flex;
    flex-direction: row;
}
.howToTrade-main-content{
    width: 80%;
    padding: 0px 50px;
    text-align: center;
    h2{
        color: var(--color-black-solid, #000);
        font-family: 'Outfit', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;    
    }
}
.howToTrade-main-content-list{
    text-align: start;
    margin-top: 20px;
    width: 100%;
    span{
        color: var(--color-black-solid, #000);
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

    }
    p{
        color: var(--color-black-solid, #000);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 0 20px 0;
        text-align: justify;
    }
}
@media only screen and (max-width: 1018px){
    .howToTrade-main-container{
        padding: 20px;
    }
    .howToTrade-main-content{
        padding: 0 20px;
        h2{
            font-size: 40px;
        }
    }
    .howToTrade-main-content-list{
        span{
            font-size: 20px;
            font-weight: 600;
        }
        p{
            font-size: 18px;
        }
    }
}
@media only screen and (max-width: 810px){
    .howToTrade-main-container{
        flex-direction: column;
        align-items: center;
    }
    .howToTrade-main-image{
        img{
            width: 280px;
        }
    }
    .howToTrade-main-content{
        margin-top: 20px;
        width: 100%;
    }
}