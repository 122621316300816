.joinNow-main{
    background-color: #EF8508;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    padding: 140px 0;
    background-image: url('../../../Assets/Home/JoinNowBg.png');
    background-repeat: no-repeat;
    
    h2{
        color: var(--hamsterkombatgame-io-white, var(--color-white-solid, #FFF));
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-8, 8vw);
        font-style: normal;
        font-weight: var(--font-weight-700, 700);
        line-height: var(--line-height-84, 84px); /* 105% */
    }

    h4{
        color: var(--hamsterkombatgame-io-white-80, var(--color-white-80, rgba(255, 255, 255, 0.80)));
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 4vw;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: var(--line-height-40, 40px); /* 125% */
    }

    button{
        width: 426px;
        height: 121px;
        flex-shrink: 0;
        border-radius: 80px;
        border: var(--stroke-weight-8, 8px) solid #FFF;

        color: var(--color-white-solid, #FFF);
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 40px;
        font-style: normal;
        font-weight: var(--font-weight-700, 700);
        line-height: var(--line-height-84, 84px); /* 210% */
    }
}
@media only screen and (max-width: 940px){
    .joinNow-main{
        button{
            width: 350px;
            height: 80px;
            font-size: 28px;
            line-height: normal;
        }
    }
}