.nft-main{
    background-color: #323232;
}
.nft-main-container{
    h2{
        color: #FFF;
        font-family: Outfit;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 50px; /* 66.667% */
        text-align: center;
    }
    h4{
        color: #BBB;
        text-align: center;
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 166.667% */
        margin-top: 40px;
    }
}
.nft-main-card-container{
    margin-top: 51px;
    align-items: center;
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width: 650px) {
    .nft-main-container{
        h2{
            font-size: 40px;
            line-height: normal;
        }
        h4{
            font-size: 20px;
            line-height: normal;
        }
    }
}
@media only screen and (max-width: 440px) {
    .nft-main-container{
        h2{
            font-size: 32px;
            line-height: normal;
        }
        h4{
            font-size: 14px;
            line-height: normal;
        }
    }
}