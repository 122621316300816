.airdropTask-main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.airdropTask-main-head{
    width: 98%;
    h2{
        color: #323232;
        font-family: 'Inter', 'san-serif';
        font-size: 80px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    h4{
        color: #323232;
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 32px;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: var(--line-height-40, 40px); /* 125% */
        line-height: 100px;
    }
}
.airdopTask-main-tasks{
    width: 80%;
}
@media only screen and (max-width: 940px){
    .airdropTask-main-head{
        h2{
            font-size: 68px;
        }
        h4{
            line-height: normal;
            margin-top: 20px;
            font-size: 28px;
        }
    }
    .airdopTask-main-tasks{
        width: 95%;
    }
}