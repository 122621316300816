.authCommon{
    display: flex;
    flex-direction: row;
    background: #323232;
    /* background: radial-gradient(53.39% 53.39% at 50% 50%, #403963 0%, #181336 79.11%); */
    min-height: 100vh;
}
.authBlock-image{
    margin: 20px 0;
    width: 50%;
}
.authImage{
    /* margin: 40px 0 0 10px; */
    object-fit: cover;
    /* position: absolute; */
}
.authBlock-content{
    background-color: white;
    width: 100%;
    padding: 100px 40px 0 40px;
    border: none;
    border-radius: 40px 0 0 40px;
}
.authBlock-content-head{
    color: #1e1840;
    font-size: 54px;
    font-weight: 700;
}
.login-form{
    display: flex;
    flex-direction: column;

}
.form-input{
    margin: 10px 0px 10px 0px ;
    width: 100%;
}
.login-form-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.login-form-name .form-input{
    margin: 10px 0px 10px 2px;
}
.auth-button{
    font-size: 20px;
    font-weight: 700;
    background-color: #323232;
    color: white;
    border-radius: 15px;
    width: 100%;
    align-self: center;
    padding: 16px 0;
}
.auth-already{
    margin: 10px 0 10px 0;
}
.auth-already p{
    color: #00000099
}
.auth-already a{
    color: var(--color-black-solid, #EF8508);
    
}
.login-fp{
    display: flex;
    justify-content: end;
    p{
        width: max-content;
        align-items: end;
        color: var(--color-black-solid, #EF8508);
        cursor: pointer;
    }
}
.authCopyright{
    position: fixed;
    bottom: 0;
    margin: 5px;
}
.authCopyright{
    color: #e0dbff8a;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.44px;
}
.authCopyright-mobile{
    display: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.44px;
    background-color: #323232;
    width: 100%;
    padding: 15px 5px;
    text-align: center;
}
@media only screen and (max-width: 1050px){
    .authCommon{
        flex-direction: column;
        width: 100%;
    }
    .authBlock-image{
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 20px 20px 20px 20px;
    }
    .authImage{
        width: 100%;
        height: 350px;
        margin: 0;
        object-fit: cover;
    }
    .authBlock-content{
        border-radius: 40px 40px 0 0;
        padding: 20px;
        height: auto;
    }
    .authBlock-content-head{
        font-size: 36px;
        text-align: center;
    }
    .login-form-name{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    .authCopyright{
        display: none;
    }
    .mob{
        display: none;
    }
    .authCopyright-mobile{
        display: block;
    }
}

input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}
input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}