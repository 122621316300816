.dashboard-main{
    background-color: #323232;
}
.dashboard-main-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
}
.dashboard-main-header-logo{
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 15px;
    img{
        width: 70px;
        height: 70px;
    }

    p{
        color: var(--hamsterkombatgame-io-white, #FFF);
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-32, 32px);
        font-style: normal;
        font-weight: var(--font-weight-500, 500);
        line-height: var(--font-size-32, 32px); /* 100% */
    }
}
.dashboard-main-contract-address{
    display: flex;
    flex-direction: row;
    border-radius: 30px;
    background: var(--color-white-12, rgba(255, 255, 255, 0.12));
    padding: 8px 24px;
    gap: 15px;
    margin: 0;
}
.dashboard-main-contract-address-a1{
    h4{
        color: var(--hamsterkombatgame-io-white-50, var(--color-white-50, rgba(255, 255, 255, 0.50)));
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-14, 14px);
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: normal;
    }
    h2{
        color: var(--hamsterkombatgame-io-white, #FFF);
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-16, 16px);
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: normal;
        text-overflow: ellipsis;
    }
}
.dashboard-main-contract-address-a2{
    display: flex;
    align-items: center;
}
.dashboard-main-card-container{
    padding: 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    align-items: center;
}

.dashboard-main-balance-card{
    padding: 27px 35px;
    max-width: 417px;
    height: 228px;
    flex-shrink: 0;
    border-radius: 40px;
    background: linear-gradient(106deg, #6739C9 11.61%, #8378B6 84.92%);
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12.5px);
    h2{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.15px;
        margin-bottom: 13px;
    }
    h4{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.15px;
    }
    h1{
        color: #12F29C;
        font-family: 'Outfit', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.15px;
        margin-bottom: 17px;
    }
}
.balance-card-button-container{
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
}
.balance-card-trade-button{
    border-radius: 15px;
    background: #F09326;
    width: 158px;
    height: 43px;
    flex-shrink: 0;
    color: #FFF;
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
}
.balance-card-withdraw-button{
    border-radius: 15px;
    background: #12F29C;
    width: 158px;
    height: 43px;
    flex-shrink: 0;
    color: #FFF;
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
}
.dashboard-main-airdrop-card{
    background: url('../../Assets/Dashboard/AirdropCardBg.png'), linear-gradient(287deg, #08B185 9.59%, #076F54 90.62%);
    object-fit: cover;
    background-size: 100%;
    padding: 27px 19px;
    border-radius: 40px;
    background-repeat: no-repeat;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12.5px);
    width: 387px;
    height: 228px;
    flex-shrink: 0;
    h2{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.15px;
        margin-bottom: 5px;
    }
    h3{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.15px;
        text-align: justify;
    }
    h4{
        color: #FFF;
        font-family: 'Outfit',sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.15px;
        text-align: justify;
    }

}
.airdrop-card-button-container{
    display: flex;
    justify-content: end;
    align-items: center;
}
.airdrop-card-join-button{
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.25);    
    width: 158px;
    height: 43px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
}
.dashboard-main-nft-card{
    padding: 27px 19px;
    border-radius: 40px;
    background: linear-gradient(106deg, #BF921D 11.61%, #FBB706 84.92%);
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12.5px);
    width: 387px;
    height: 228px;
    flex-shrink: 0;

    h2{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.15px;
        margin-bottom: 10px;
    }
    h4{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.15px;
    }
}
.return-calculator-container{
    padding: 19px 50px;
    border-radius: 40px;
    background: linear-gradient(121deg, #FFF 29.4%, #BABABA 124.35%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12.5px);
    width: 529px;
    height: 405px;
    flex-shrink: 0;
    text-align: center;
    h2{
        color: #242424;
        text-align: center;
        font-family: 'Outfit', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.15px;
    }
}
.calculator-slider-container-value{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 28px;
    p{
        color: var(--color-black-solid, #000);
        text-align: center;
        font-family: 'Outfit',sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.15px;
    }
    span{
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: flex-end; */
        color: #29BBCF;
        text-align: right;
        font-family: 'Outfit', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.15px;
        background: #CEF9FF;
        width: 122px;
        height: 28px;
        flex-shrink: 0;

        input{
            width: max-content;
            min-width: 10px;
            /* width: 10px; */
            max-width: auto;
            background-color: transparent;
        }
    }

}
.return-total-amount-container-main{
    border-radius: 22px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    width: 418px;
    height: 117px;
    flex-shrink: 0;
    padding: 15px 40px;
    margin-top: 15px;
}
.return-total-amount-container{
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
    p{
        color: #8E8E8E;
        font-family: 'Outfit', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.15px;
    }
    span{
        color: #8E8E8E;
        font-family: 'Outfit', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.15px;
    }
}
@media only screen and (max-width: 922px){
    .dashboard-main-header{
        padding: 10px;
    }
    .dashboard-main-header-logo{
        gap: 5px;
        img{
            width: 50px;
            height: 50px;
        }
        p{
            font-size: 28px;
        }
    }
}
@media only screen and (max-width: 811px){
    .dashboard-main-contract-address{
        margin: 0;
    }
}
@media only screen and (max-width: 650px){
    .return-calculator-container{
        width: 100%;
        border-radius: 25px;
    }
    .return-total-amount-container-main{
        width: 100%;
        border-radius: 10px;
    }
    .calculator-slider-container-value{
        p{
            font-size: 18px;
        }
        span{
            font-size: 18px;
            width: 95px;
        }
    }
}
@media only screen and (max-width: 590px){
    .dashboard-main-header-logo{
        gap: 5px;
        img{
            width: 40px;
            height: 40px;
        }
        p{
            font-size: 22px;
        }
    }
    .dashboard-main-contract-address-a1{
        width: 130px;
        h4{
            font-size: 10px;
        }
        h2{
            font-size: 14px;
        }
    }
    .dashboard-main-card-container{
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding: 60px 20px;
    }
}
@media only screen and (max-width: 490px){
    .return-calculator-container{
        padding: 19px;
        height: auto;
        
    }
    .return-total-amount-container-main{
        padding: 15px;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 465px){
    .dashboard-main-header-logo{
        img{
            width: 50px;
            height: 50px;
        }
        p{
            display: none;
        }
    }
    .dashboard-main-contract-address{
        width: 60%;
    }
    .dashboard-main-contract-address-a1{
        width: 80%;
    }
    .dashboard-main-balance-card, .dashboard-main-airdrop-card, .dashboard-main-nft-card{
    
        padding: 27px;
        max-width: none;
        width: 100%;
        aspect-ratio: 3/1;
        height: auto;
    }
    
    
}
@media only screen and (max-width: 436px){
    .balance-card-trade-button{
        width: 100%;
    }
    .balance-card-withdraw-button{
        width: 100%;
    }
}

