.support-main-container{
    background-color: #EF8508;
    height: 70vh;
    background-image: url('../../Assets/Home/JoinNowBg.png');
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    h2{
        color: var(--hamsterkombatgame-io-white, #FFF);
        text-align: center;
        
        /* hamsterkombatgame.io/Semantic/Heading 1 */
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-80, 80px);
        font-style: normal;
        font-weight: var(--font-weight-700, 700);
        line-height: var(--line-height-84, 84px); /* 105% */
    }

    h4{
        color: var(--hamsterkombatgame-io-white-80, var(--color-white-80, rgba(255, 255, 255, 0.80)));
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 32px;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: var(--line-height-40, 40px); /* 125% */
    }
}
.support-main-icon-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    a{
        background-color: #000000;
        svg{
            font-size: 35px;
            color: transparent;
        }
    }
}

@media only screen and (max-width: 650px) { 
    .support-main-container{
        h2{
            font-size: 50px;
        }
        h4{
            font-size: 22px;
        }
    }
}