@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
.roadmap-main{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../Assets/Home/RoadmapBg.png');
    background-size: cover;
}

.roadmap-container{
    width: 80%;
    height: 740px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 122px 65px;
    
    
}

.roadmap-container-left{
    width: 50%;
    background-color: #323232;
    border-radius: 50px 0px 0px 50px;
    padding: 30px 60px;

    h2{
        color: var(--color-white-solid, #FFF);
        text-align: center;

        /* hamsterkombatgame.io/Semantic/Heading 1 */
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-80, 80px);
        font-style: normal;
        font-weight: var(--font-weight-700, 700);
        line-height: var(--line-height-84, 84px); /* 105% */
    }
}
.roadmap-container-right{
    width: 50%;
    
    img{
        height: 100%;
        border-radius: 0px 50px 50px 0px;

        object-fit: cover;
    }
}



.roadmap-list{

    margin-top: 30px;

    h4{
        color: var(--color-white-solid, #FFF);
        font-family: Outfit, 'sans-serif';
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 100% */
        text-decoration-line: underline;
    }

    ul{
        color: var(--color-white-solid, #FFF);
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 20px;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: 30px; /* 150% */

        list-style-type: disc;
        margin-left: 15px;
        margin-top: 10px;
        
    }
}
@media only screen and (max-width: 1120px) {
    .roadmap-container{
    }
    .roadmap-container-left{
        padding: 30px;
        h2{
            font-size: 60px;
        }
        h4{
            font-size: 26px;
        }
        li{
            font-size: 16px;
        }
    }
}
@media only screen and (max-width: 950px){
    .roadmap-container{
        flex-direction: column-reverse;
        margin: 0 0 0 0;
        height: auto;
        padding: 40px 0px;
        align-items: center;
        width: 90%;
    }
    .roadmap-container-left{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0 0 50px 50px;
        width: 100%;
    }
    .roadmap-list{
        width: 80%;
    }
    .roadmap-container-right{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            height: 500px;
            border-radius: 50px 50px 0px 0px;
            
        }
    }
}
@media only screen and (max-width: 740px){
    .roadmap-container-left{
        width: 100%;
        h2{
        }
    }
    .roadmap-container-right{
        img{
            height: 400px;
            width: 100%;

        }
    }
}