@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.getStarted-main{
    background-color: #323232;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
}   

.getStarted-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.getStarted-nav-social-p1{
    margin-top: 21px;
    margin-left: 40px;
    margin-right: 10px;
    width: 220px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--item-spacing-xxs, 4px);
    border-radius: 100px;
    border: var(--stroke-weight-1, 1px) solid var(--color-white-15, rgba(255, 255, 255, 0.15));
    color: var(--color-white-50, rgba(255, 255, 255, 0.50));
    font-family: var(--font-family-Font-1, Outfit);
    font-size: var(--font-size-22, 22px);
    font-style: normal;
    font-weight: var(--font-weight-500, 500);
    line-height: var(--font-size-22, 22px); /* 100% */
}
.social-container-1{
    display: flex;
    width: 52px;
    height: 52px;
    padding: 11px;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    background: var(--color-white-15, rgba(255, 255, 255, 0.15));
}

.social-container-2{
    display: flex;
    width: 52px;
    height: 52px;
    padding: 11px;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: white solid 0.5px;
    background: var(--color-white-15, rgba(255, 255, 255, 0.15));
}

.white-border{
    border: #ffffff solid 1px ;
}

.getStarted-nav-social-p2{
    justify-content: space-around;
    border-color: white;
    background-color: #EF8508;
    color: #ffffff;

    .social-container-1{
        border-color: white;
    }
}

.getStarted-nav-contract{
    padding: 32px;
    border-radius: 0px 0px var(--radius-70, 70px) var(--radius-70, 70px);
    border-right: var(--stroke-weight-8, 8px) solid #2A2832;
    border-bottom: var(--stroke-weight-8, 8px) solid #2A2832;
    border-left: var(--stroke-weight-8, 8px) solid #2A2832;
}
.getStarted-nav-contract-head{
    display: flex;
    align-items: center;
    /* background-color: orange; */
    flex-direction: row;
    justify-content: center;
    gap: var(--item-spacing-xxs, 16px);
    img{
        width: 70px;
        height: 70px;
        border-radius: 70px;
    }
    p{
        color: var(--hamsterkombatgame-io-white, var(--color-white-solid, #FFF));
        /* hamsterkombatgame.io/Outfit/Medium 32 */
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-32, 32px);
        font-style: normal;
        font-weight: var(--font-weight-500, 500);
    }
}
.getStarted-nav-contract-address{

    display: flex;
    flex-direction: row;
    border-radius: 30px;
    background: var(--color-white-12, rgba(255, 255, 255, 0.12));
    padding: 8px 24px;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    h4{
        color: var(--hamsterkombatgame-io-white-50, var(--color-white-50, rgba(255, 255, 255, 0.50)));
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-14, 14px);
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: normal;
    }

    h2{
        color: var(--hamsterkombatgame-io-white, var(--color-white-solid, #FFF));

        /* hamsterkombatgame.io/Outfit/Regular 16 */
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-16, 16px);
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.getStarted-nav-contract-address-a2{
    cursor: pointer;
}

.getStarted-nav-hanburger{
    display: flex;
    justify-content: end;
    padding: 20px;
}
.hamNav-menu-item{
}
.getStarted-main-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    h2{
        color: var(--hamsterkombatgame-io-white, var(--color-white-solid, #FFF));
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-80, 80px);
        font-style: normal;
        font-weight: var(--font-weight-700, 700);
        line-height: var(--line-height-84, 84px); /* 105% */
    }
    h3{
        width: 65%;
        margin-top: 40px;
        color: var(--hamsterkombatgame-io-white-80, var(--color-white-80, rgba(255, 255, 255, 0.80)));
        text-align: center;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 30px;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: var(--line-height-40, 40px); /* 125% */
    }
}

.getStarted-main-buttonArea{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    img{
        width: 200px;
    }
    
}

.getStarted-main-buttonArea-button-container{
    z-index: 1;
    border-radius: 71px;
    gap: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background: linear-gradient(90deg, #EF8508 1.46%, #EF8508 95.5%); */
    /* filter: blur(13px); */

}

.getStarted-main-buttonArea-button{
    z-index: 2;
    width: 289px;
    height: 80px;
    border-radius: 71px;
    border: var(--stroke-weight-2, 2px) solid #EF8508;
    background: linear-gradient(90deg, #EF8508 1.46%, #EF8508 95.5%);

    color: var(--color-white-solid, #FFF);
    text-align: center;
    font-family: var(--font-family-Font-1, Outfit);
    font-size: 32px;
    font-style: normal;
    font-weight: var(--font-weight-500, 500);
    line-height: var(--font-size-26, 26px); /* 65% */


}
.getStarted-main-buttonArea-button-reverse{
    color: #EF8508;
    background: transparent;

}

@media only screen and (max-width: 880px){

    .getStarted-nav-social-p1, .getStarted-nav-social-p2{
        margin-left: 10px;
        padding: 15px;
        font-size: 18px;
        width: 80%;

        svg{
            font-size: medium;
        }
        .social-container-1, .social-container-2{
            width: 10px;
            height: 10px;
        }
    }
    .getStarted-nav-contract-head{
        img{
            width: 40px;
            height: 40px;
        }
        p{
            color: #FFF;
            font-family: var(--font-family-Font-1, Outfit);
            font-size: 24px;
            font-style: normal;
            font-weight: var(--font-weight-500, 500);
            line-height: var(--font-size-32, 32px); /* 228.571% */
        }
    }
    .getStarted-nav-contract-address-a1{
        width: 200px;
    }

    .getStarted-main-heading{
        h2{
            font-size: 65px;
        }
        h3{
            font-size: 24px;
            width: 86%;
        }
    }
    .getStarted-main-buttonArea{
        img{
            width: 150px;
        }
    }
    .getStarted-main-buttonArea-button{
        font-size: 24px;
        width: 305px;
        height: 88px;
    }
}
@media only screen and (max-width: 740px){
    .getStarted-nav-social-p1{
        padding: 0;
        width: max-content;
        font-size: 10px;
    }
    .getStarted-nav-contract{
        width: 1000px;
        flex-basis: content;
        padding: 20px;
    }
}
@media only screen and (max-width: 580px){
    .getStarted-nav-hanburger{
        padding: 0px;
        flex-basis: 10%;
    }
    .getStarted-nav-contract{
        padding: 16px;
        border-radius: 0px 0px var(--radius-50, 50px) var(--radius-50, 50px);
        border-right: var(--stroke-weight-4, 4px) solid #2A2832;
        border-bottom: var(--stroke-weight-4, 4px) solid #2A2832;
        border-left: var(--stroke-weight-4, 4px) solid #2A2832;
    }
    .getStarted-nav-contract-address{
        margin-top: 10px;
        padding: 4px 16px;
        /* width: 80%; */
        h4{
            font-size: 8px;
        }
        h2{
            font-size: 10px;
        }
        svg{
            font-size: 16px;
        }
    }

    .getStarted-main-heading{
        margin-bottom: 24px;
        h2{
            font-size: 40px;
            line-height: normal;
            padding: 0 2px;
        }
        h3{
            font-size: 20px;
            line-height: normal;
        }
    }
    .getStarted-main-buttonArea{
        justify-content: space-between;
        img{
            width: 100px;
            height: auto;
        }
    }
    .getStarted-main-buttonArea-button{
        width: 200px;
        height: 60px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 450px){
    .getStarted-nav-contract-head{
        img{
            width: 28px;
            height: 28px;
        }
        p{
            font-size: 20px;
        }
    }
    .getStarted-nav-contract{
        max-width: fit-content;
        width: 58%;
    }
    .getStarted-nav-contract-address{
        width: 100%;

    }
    .getStarted-nav-contract-address-a1{
        width: 80%;
    }
    .getStarted-main-buttonArea{
        justify-content: space-between;
        img{
            width: 60px;
            height: auto;
        }
    }
    .getStarted-main-buttonArea-button{
        width: 180px;
        height: 60px;
        font-size: 20px;
    }
}