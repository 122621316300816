.taskWidget3-main{
    display: flex;
    flex-direction: row;
    border-radius: 40px;
    background: var(--color-white-solid, #242424);
    box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    margin: 11px 0;
    cursor: pointer;
    
    h2{
        color: #ffffff;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 20px;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: var(--line-height-20, 20px); /* 125% */
        text-align: start;
    }
    svg{
        color: #ffffff;
    }
}
@media only screen and (max-width: 940px){
    .taskWidget3-main{
        text-align: left;
        h2{
            font-size: 18px;
            padding-right: 80px;
            line-height: normal;
        }
    }
}
@media only screen and (max-width: 722px){
    .taskWidget3-main{
        h2{
            font-size: 16px;
            padding-right: 65px;
            font-weight: 400;
        }
    }
}
@media only screen and (max-width: 630px){
    .taskWidget3-main{
        h2{
            font-size: 14px;
            padding-right: 40px;
        }
    }
}
@media only screen and (max-width: 568px){
    .taskWidget3-main{
        padding: 20px;
        border-radius: 30px;
        h2{
            font-size: 12px;
            padding-right: 32px;
        }
    }
}
@media only screen and (max-width: 510px){
    .taskWidget3-main{
        h2{
            font-size: 10px;
            padding-right: 14px;
        }
    }
}