.authNav-main{
    padding: 40px;
    display: flex;
    justify-content: space-between;
}
.authNav-main-nav-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    p{
        color: var(--color-blue-18, #2A2832);
        font-family: var(--font-family-Font-1, Outfit);
        font-size: var(--font-size-32, 32px);
        font-style: normal;
        font-weight: var(--font-weight-500, 500);
        line-height: var(--font-size-32, 32px); 
    }
}
@media only screen and (max-width: 560px){
    .authNav-main{
        padding: 20px;
    }
    .authNav-main-nav-logo{
        p{
            font-size: 28px;
        }
    }
}
@media only screen and (max-width: 411px){
    .authNav-main{
        padding: 10px;
    }
    .authNav-main-nav-logo{
        gap: 5px;
        img{
            width: 50px;
        }
        p{
            font-size: 20px;
        }
    }
}