.card1-main{
    width: 802px;
    border-radius: 60px;
    background: rgba(187, 187, 187, 0.20);
    padding: 78px 101px;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}
.card1-main-container{
    h3{
        color: #FFF;
        font-family: Outfit;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 66.667% */
        margin: 50px 0;
    }
}
@media only screen and (max-width: 850px) {
    .card1-main{
        padding: 40px;
        width: 80%;
    }
    .card1-main-container{
        h3{
            font-size: 38px;
        }
    }
}
@media only screen and (max-width: 665px) {
    .card1-main-container{
        h3{
            font-size: 30px;
        }
    }
}
@media only screen and (max-width: 560px) {
    .card1-main{
        padding: 15px;
        border-radius: 45px;
        width: 90%;
    }
    .card1-main-container{
        h3{
            font-size: 30px;
        }
    }
}