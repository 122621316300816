.airdropEvent-main{
    background-color: #323232;
}
.airdropEvent-main-container{
    text-align: center;
}
.airdropEvent-main-head{
    h2{
        color: #FFF;
        font-family: 'Outfit', sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 80% */
    }
    h4{
        color: #BBB;
        text-align: center;
        font-family: 'Outfit', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 200% */
        margin-top: 20px;
    }
}
.airdropEvent-main-body{
    display: flex;
    flex-direction: row;
}
.airdropEvent-main-body-task{
    width: 80%;
}
@media only screen and (max-width: 940px){
    .airdropEvent-main-body{
        flex-direction: column;
    }
    .airdropEvent-main-body-image{
        display: flex;
        justify-content: center;
        img{
            width: 280px;
        }
    }
    .airdropEvent-main-body-task{
        width: 100%;
        padding: 0 10px;
    }
}
@media only screen and (max-width: 560px){
    .airdropEvent-main-head{
        h2{
            font-size: 35px;
        }
        h4{
            line-height: normal;
            font-size: 18px;
        }
    }
}