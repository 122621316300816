.taskWidget2-main{
    display: flex;
    flex-direction: row;
    border-radius: 40px;
    background: var(--color-white-solid, #242424);
    box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    margin: 30px 0;
    cursor: pointer;
    
    h2{
        color: #ffffff;
        font-family: var(--font-family-Font-1, Outfit);
        font-size: 24px;
        font-style: normal;
        font-weight: var(--font-weight-400, 400);
        line-height: var(--line-height-40, 40px); /* 125% */
        text-align: start;
    }
    svg{
        color: #ffffff;
    }
}
@media only screen and (max-width: 940px){
    .taskWidget2-main{
        text-align: left;
        h2{
            font-size: 28px;
            padding-right: 100px;
            line-height: normal;
        }
    }
}
@media only screen and (max-width: 722px){
    .taskWidget2-main{
        h2{
            font-size: 24px;
            padding-right: 80px;
            font-weight: 400;
        }
    }
}
@media only screen and (max-width: 630px){
    .taskWidget2-main{
        h2{
            font-size: 22px;
            padding-right: 60px;
        }
    }
}
@media only screen and (max-width: 568px){
    .taskWidget2-main{
        padding: 20px;
        margin: 40px 0;
        h2{
            font-size: 20px;
            padding-right: 40px;
        }
    }
}
@media only screen and (max-width: 510px){
    .taskWidget2-main{
        h2{
            font-size: 18px;
            padding-right: 18px;
        }
    }
}